/* global Element, HTMLScriptElement, DOMRect, Location, Document */
import cookies from 'js-cookie'
import { ArbitraryObject } from './types'

/**
* assign
* @param target Target object
* @param sources Object to be assigned to target
* @returns Target object with sources
*/
export function assign (target: ArbitraryObject, ...sources: ArbitraryObject[]): any {
  sources.filter(s => s).forEach((source) => {
    Object.keys(source).forEach((key) => (target[key] = source[key]))
  })
  return target
}

/**
* closest
* https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
* @param element Element to traverse up from
* @param selector A selector to search for matching parents or element itself
* @return  Element which is the closest ancestor matching selector
*/
export const closest = (() => {
  if (typeof window === 'undefined') {
    return () => null
  }

  const proto: Element & { msMatchesSelector?: any } = window.Element.prototype

  const match = proto.matches || proto.msMatchesSelector || proto.webkitMatchesSelector
  return (el: Element, selector: string): Element | null => {
    if (proto.closest as Element['closest'] | undefined) return el.closest(selector)

    for (let elm: Element | null = el; elm; elm = elm.parentElement) if (match.call(elm, selector)) return elm
    return null
  }
})()

interface LoadOnceOptions {
  src: string;
  onload?: Function;
  crossorigin?: boolean;
}

/**
 * loadJs
 */
export function loadOnce ({ src, onload = Function.prototype, crossorigin = true }: LoadOnceOptions): void {
  const script: HTMLScriptElement & { queue?: any[] } = document.querySelector(`script[src="${src}"]`) || document.createElement('script')
  const queue = script.queue = script.queue || [] // Queue up all callbacks

  if (queue.length) queue.push(onload) // Script is loading
  else if (script.parentNode) onload(script) // Script is loaded
  else {
    queue.push(onload)
    script.async = true
    script.onload = () => queue.splice(0).forEach((fn) => fn(script)) // Empty and run queue
    script.setAttribute('src', src) // setAttribute for later discovery with [src=""]
    if (crossorigin && src.indexOf('//localhost') === -1) script.setAttribute('crossorigin', 'anonymous')

    // @ts-ignore IE 8 has no document.head
    document.head.appendChild(script)
  }
}

/**
* parseUrl
* @param urlString Url
* @returns location-object, see https://developer.mozilla.org/en-US/docs/Web/API/Location#Properties
*/
export function parseUrl (urlString: string): Location {
  return assign(document.createElement('a'), { href: urlString })
}

export function isElementVisible (el: Element, elementVisibilityRatio: number = 1): boolean {
  const rect: DOMRect = el.getBoundingClientRect()

  const style = window.getComputedStyle(el)
  if (style.visibility === 'hidden') return false
  const visibleWidth: number = clamp(0, window.innerWidth, rect.right) - clamp(0, window.innerWidth, rect.left)
  const visibleHeight: number = clamp(0, window.innerHeight, rect.bottom) - clamp(0, window.innerHeight, rect.top)

  let isVisible = (visibleWidth * visibleHeight) / (rect.width * rect.height) >= elementVisibilityRatio

  for (let ancestorElement: Element | null = el; ancestorElement && isVisible; ancestorElement = ancestorElement.parentElement) {
    const style = window.getComputedStyle(el)
    if (style.visibility === 'hidden') return false
    const scroll = (style.overflowY === 'scroll' || style.overflowX === 'scroll' || style.overflow === 'scroll') && ancestorElement.getBoundingClientRect()

    if (scroll) {
      const visibleWidth: number = clamp(0, scroll.width, rect.right) - clamp(0, scroll.width, rect.left)
      const visibleHeight: number = clamp(0, scroll.height, rect.bottom) - clamp(0, scroll.height, rect.top)
      isVisible = (visibleWidth * visibleHeight) / (rect.width * rect.height) >= elementVisibilityRatio
    }
  }

  return isVisible
}

/**
* cookie
* @param key Cookie name
* @param value Object to store
*/
export function cookie (key: string, value?: object | false, expires = 1): any | void { // 1 day default expiry
  if (value === false) cookies.remove(key)
  else if (typeof value === 'undefined') {
    return cookies.getJSON(key)
  } else {
    cookies.set(key, value, { expires })
  }
}

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
/**
* debounce
* @param callback The function to debounce
* @param ms The number of milliseconds to delay
* @return The new debounced function
*/
export function debounce<T extends Function> (callback: T, ms: number): (...args: ArgumentTypes<T>) => void {
  let timer: number
  return function (...args) {
    const self = this
    clearTimeout(timer)
    timer = window.setTimeout(() => callback.apply(self, args), ms)
  }
}

export function stripProtocolAndWww (path: string): string {
  // @ts-ignore
  return path.split('://').pop().replace(/^www\./, '')
}

export function queryAll (selector: string, element: Element | Document = document): Element[] {
  return [].slice.call(element.querySelectorAll(selector))
}

export function throttle (fn: Function, limit: number): Function {
  let inThrottle: boolean = false
  return function () {
    const args = arguments
    const context = this
    if (!inThrottle) {
      fn.apply(context, args)
      inThrottle = true
      setTimeout(() => {
        inThrottle = false
      }, limit)
    }
  }
}

export function clamp (min: number, max: number, value: number): number {
  // Code borrowed from Ramda.js
  if (min > max) {
    throw new Error('min must not be greater than max in clamp(min, max, value)')
  }
  return value < min
    ? min
    : value > max
      ? max
      : value
}

export function determineUnloadEvent () {
  const onIOS = navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i)

  return onIOS ? 'pagehide' : 'beforeunload'
}
